import React, { useCallback, useMemo } from 'react';
import { Bot } from './types';

interface BotScreenProps {
    bots: Bot[];
    socket: WebSocket;
    onGoToBotDetail: (ev: string) => void;
    isAdmin: boolean
    enableBot: (ev: string, checked: boolean) => void
}

export default function BotsScreen({ bots, socket, onGoToBotDetail, isAdmin, enableBot }: BotScreenProps) {
    const onStartGame = useCallback(() => {
        socket.send(JSON.stringify({
            op: "start"
        }))
    }, [socket]);

    const onClickBot = useCallback(
        (ev) => {
            ev.preventDefault();
            onGoToBotDetail(ev.target.innerText)
        },
        [onGoToBotDetail]
    )

    return <div>
        <h2>Dating Game KOTH - Tournament Currently Not Running</h2>

        <div className='card'>
            <table>
                <tr>
                    {
                        isAdmin ? <th>Enabled</th> : null
                    }
                    <th>Icon</th>
                    <th>Name</th>
                    <th>Last Total Score</th>
                    <th>Proportion Accepted</th>
                    <th>Ideal Outcome</th>
                    <th>Worst Outcome</th>
                    <th>Left Alone</th>
                </tr>
                {bots.filter(bot => isAdmin || bot.enabled).map((bot) => (
                    <tr key={bot.name}>
                        {
                            isAdmin ? <td>

                                <input type="checkbox"
                                    checked={bot.enabled}
                                    disabled={bot.special}
                                    onChange={(ev) => enableBot(bot.name, ev.target.checked)}
                                />

                            </td> : null
                        }
                        <td><img src={bot.icon} className="bot-icon bot-icon-small"></img></td>
                        <td><a href='' onClick={onClickBot}>{bot.name}</a></td>
                        <td>{bot.last_score.toFixed(1)}</td>
                        <td>{(bot.last_proportion_accepted * 100).toFixed(1)}%</td>
                        <td>{(bot.last_proportion_perfect * 100).toFixed(1)}%</td>
                        <td>{(bot.last_proportion_worst * 100).toFixed(1)}%</td>
                        <td>{(bot.last_proportion_forever_alone * 100).toFixed(1)}%</td>
                    </tr>
                ))
                }
            </table>
        </div>

        <button onClick={onStartGame}>Start Tournament</button>
        <button onClick={() => onGoToBotDetail('about')}>How to play</button>
    </div>
}