import React from 'react';
import { Bot } from './types';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { python } from 'react-syntax-highlighter/dist/esm/languages/hljs';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

SyntaxHighlighter.registerLanguage('python', python);

interface BotDetailScreenProps {
    bot: Bot
    onBack: () => void
}

export default function BotDetailScreen({ bot, onBack }: BotDetailScreenProps) {
    return <div>
        <h2>{bot.name}</h2>

        <div className='card'>
            <div className='bot-stats'>
                <div>
                    <div>Score:</div>
                    <div className='big-number'>{bot.last_score.toFixed(4)}</div>
                </div>

                <div>
                    <div>Proportion Accepted</div>
                    <div className='big-number'>{(bot.last_proportion_accepted * 100).toFixed(1)} %</div>
                </div>


                <div>
                    <div>Ideal Outcome</div>
                    <div className='big-number'>{(bot.last_proportion_perfect * 100).toFixed(1)} %</div>
                </div>


                <div>
                    <div>Worst Outcome</div>
                    <div className='big-number'>{(bot.last_proportion_worst * 100).toFixed(1)} %</div>
                </div>


                <div>
                    <div>Forever Alone</div>
                    <div className='big-number'>{(bot.last_proportion_forever_alone * 100).toFixed(1)} %</div>
                </div>
            </div>

            <SyntaxHighlighter language="python" style={dracula}>{bot.code}</SyntaxHighlighter>
        </div>

        <button onClick={onBack}>&laquo; Back</button>
        {bot.link && <a className='button' href={bot.link}>Go to original post</a>}
    </div>
}