import React from 'react';

export default function About({ goToPage }) {
    return <>
        <div className="card">
            <h1>Rules</h1>

            <p>
                The point of this game is for every bot to find their ideal dates. However, just like in real life it's hard to compare
                how compatible you are. Bots have a vague sense of one date being "better" or "worse" than previous dates, but do
                not know the actual absolute value.
            </p>

            <p>
                In this game, bots will date every other bot one by one. They can then decide to accept or reject their date. If both accept,
                they get married and are removed from the game. Their final score is how compatible they are. If all bots combinations are
                completed but some bots still remain, the remaining bots can play try again but with a small score penalty. If only
                1 bot remains or the no new matches have been made in 2 rounds, the game ends.
            </p>

            <h2>
                Scoring
            </h2>

            <p>
                Your most compatible bot will score you 1.0 points, while the least compatible bot scores 0.0. There is a 5% penalty for every
                full round your bot stays single.
            </p>

            <p>
                Your bots compatibility with a different bot is correlated but not equal to the other bots compatibility with you.
            </p>

            <h2>
                How to submit your bot
            </h2>

            <p>
                Go to <a href="https://codegolf.stackexchange.com/questions/259965/the-dating-game-secretary-problem">the question on code golf
                    stack exchange</a>. Select "post a new answer". Submit your bot in the text field. I'll add it to the game as soon as possible.
            </p>

            <p>
                Note: Please follow the rules of that site. No suicidal bots, no exploiting the controller.
            </p>
        </div>

        <button onClick={() => goToPage(undefined)}>&laquo; Back</button>
    </>

}