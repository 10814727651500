import React, { useCallback, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import BotDetailScreen from './BotDetailScreen';
import GameScreen from './GameScreen';
import BotsScreen from './BotsScreen';
import { Bot } from './types';
import { updateGameState, GameState } from './updateGameState';
import About from './About';

const getWebsocketLocation = () => {
    if (window.location.host === 'test.koth.mousetail.test:8765') {
        return 'ws://ws.test.koth.mousetail.test:8765'
    } else if (window.location.host === '127.0.0.1:1234' || window.location.host === 'localhost:1234') {
        return 'ws://127.0.0.10:8765'
    } else if (window.location.protocol === 'https:') {
        return 'wss://' + window.location.host + '/websocket'
    } else {
        return 'ws://' + window.location.host + '/websocket'
    }
}

const Main = ({ }) => {
    const [socket, setSocket] = useState<WebSocket | undefined>(undefined);
    const [isAdmin, setIsAdmin] = useState(false);
    const [started, setStarted] = useState(false);
    const [bots, setBots] = useState<Bot[]>([]);
    const [page, setPage] = useState<string | undefined>(undefined);
    const [gameState, setGameState] = useState<GameState | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const onGoToBotDetail = useCallback((page: string) => setPage(page), [])
    const goToHome = useCallback(() => setPage(undefined), [])

    useEffect(() => {
        const socket = new WebSocket(
            getWebsocketLocation(),

        );

        socket.addEventListener('close', () => {
            setError("Lost connection to the websocket, please reload the page")
        })

        socket.addEventListener('error', (ev: Event) => {
            setError('There was a error establishing a connection: ' + ev)
        })

        socket.addEventListener('open', () => {
            setSocket(socket);
            socket.send(JSON.stringify({
                'op': 'bots',
                'bot': '12'
            }))

            socket.addEventListener('message', (msg: MessageEvent) => {
                const data = JSON.parse(msg.data);
                if (data.res === "bots" || data.res === "add_bot" || data.op === "bots") {
                    setBots(data.bots);
                    setIsAdmin(data.is_admin);
                } else if (data.op === "start_game") {
                    setStarted(true);
                } else if (data.op === "error") {
                    setError(data.traceback);
                } else if (data.res !== undefined) {
                    // Do nothing
                } else if (data.op.substr(0, 5) == "game_") {
                    setStarted(true);
                    setGameState((gameState) => updateGameState(data, gameState))
                } else if (data.op === "end_game") {
                    setStarted(false);
                }
            });
        })

    }, []);

    const enableBot = (botName: string, enabled: boolean) => {
        socket?.send(JSON.stringify(
            {
                "op": "set_bot_enabled",
                "bot_name": botName,
                "enabled": enabled
            }
        ))
    }

    let component;
    if (!socket) {
        component = <div>Establishing Connection...</div>
    } else if (page === undefined && started) {
        if (gameState != undefined) {
            component = <GameScreen state={gameState!} goToBotDetail={onGoToBotDetail} />
        } else {
            component = <div>Game Starting...</div>
        }
    } else if (page === undefined && !started) {
        component = <BotsScreen bots={bots} socket={socket} onGoToBotDetail={onGoToBotDetail} isAdmin={isAdmin} enableBot={enableBot} />
    } else if (page === 'about') {
        component = <About goToPage={setPage} />
    } else {
        component = <BotDetailScreen bot={bots.find(bot => bot.name === page)!} onBack={goToHome} />
    }

    return <div className='wrapper'>
        {
            error ? <div className='modal card'>
                <h3>A Error Occurred</h3>
                <pre>{error}</pre>
                <button onClick={() => setError(undefined)}>close</button>
            </div> : undefined
        }
        {component}
    </div>
    // <div>
    //     <h2>Bots</h2>
    //     {
    //         bots.map(bot => (<div>{bot}</div>))
    //     }

    //     {
    //         started ? "started" : "not started"
    //     }<br></br>

    //     <button onClick={() => socket.send(JSON.stringify({
    //         "op": "start"
    //     }))}>Start Game</button>

    //     <br></br>

    //     <textarea
    //         value={code}
    //         onChange={(ev) => setCode(ev.target.value)}
    //     />

    //     <button onClick={() => socket.send(JSON.stringify({
    //         "op": "add_bot",
    //         "bot": code
    //     }))}>Submit bot</button>
    // </div>
}

const root = createRoot(document.getElementById('react-root')!);
root.render(<Main />)