import React, { useCallback, MouseEvent } from 'react';
import { ActiveBot } from './types';
import { GameState } from './updateGameState';
import CheckCircle from 'jsx:~icons/check_circle.svg';
import Cancel from 'jsx:~icons/cancel.svg';
import Pending from 'jsx:~icons/pending.svg';
import { GameLeaderboard } from './GameLeaderboard';

interface GameScreenProps {
    state: GameState,
    goToBotDetail: (bot: string) => void,
}

interface BotPageProps {
    bot: ActiveBot,
    direction: 'in' | 'accept' | 'reject',
    goToBotDetail: (bot: string) => void,
}

function BotPage({ bot, direction, goToBotDetail }: BotPageProps) {
    const onClickTitle = useCallback((ev: MouseEvent) => {
        ev.preventDefault();
        goToBotDetail(bot.name)
    }, [bot.name, goToBotDetail])

    return <div className={'bot-card card ' + { in: 'animate-in', reject: 'animate-down', accept: 'animate-up' }[direction]}>
        <div className='bot-name'><a href="" onClick={onClickTitle}>{bot.name}</a></div>
        <div className='bot-icon-big-container'>
            <img src={bot.icon} className="bot-icon bot-icon-big" />
            <div className='bot-icon-tag'>
                {bot.accepts !== null ? bot.accepts ? <CheckCircle fill="green" /> : <Cancel fill="red" /> : <Pending fill="grey" />}
            </div>
        </div>

        <h4>Best dates:</h4>
        <div className='bot-ranks'>
            {
                bot.top_dates.map(
                    (date, index) => (
                        <div className={index === bot.rank - 1 ? 'rank-image-active rank-image' : 'rank-image'}>
                            <img src={date} className={'bot-icon bot-icon-small'} />
                        </div>
                    )
                )
            }

            <div className={bot.rank >= 6 ? 'rank-image-active rank-image' : 'rank-image'}>
                <div className={'bot-icon-small'}>...</div>
            </div>

            <div className='bot-rank' style={{ gridColumn: Math.min(6, bot.rank) }}>
                # {bot.rank}
            </div>
        </div>

    </div>
}

const MAX_SHOWN_GAMES = 15;

export default function GameScreen({ state, goToBotDetail }: GameScreenProps) {
    return <div>
        <h2>KOTH dating game - Tournament Currently Active</h2>

        <div className='bot-card-container'>
            <div>
                <div className='big-number'>
                    {state.penalty.toFixed(2)}
                </div>
                Penalty
            </div>
            <BotPage bot={state.left} direction={state.direction} goToBotDetail={goToBotDetail} />
            <BotPage bot={state.right} direction={state.direction} goToBotDetail={goToBotDetail} />
            <div>
                <div className='big-number'>
                    {state.remaining.remaining}
                </div>
                <hr />
                <div className='big-number'>
                    {state.remaining.total}
                </div>
                <div>Bots remaining</div>
            </div>
        </div>

        <div className='card'>
            <GameLeaderboard state={state} goToBotDetail={goToBotDetail} />
        </div>
    </div>
}