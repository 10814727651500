import React from 'react';
import { GameState } from './updateGameState';

const getClasses = (botName: string, column: number, gameState: GameState) => {
    return (
        column === gameState.roundNr ? 'active-column ' : ''
    ) + (
            botName === gameState.left.name || botName === gameState.right.name ? 'active-row ' : ''
        )
}


const MAX_SHOWN_GAMES = 12;

const getRounds = (roundNr: number) => {
    return [...Array(Math.min(roundNr, MAX_SHOWN_GAMES)).keys()].map(i => i + Math.max(0, roundNr - MAX_SHOWN_GAMES));
}


export function GameLeaderboard({ state, goToBotDetail }) {
    return <>
        <h3>Ranking</h3>

        <table>
            <tbody>
                <tr>
                    <th>Bot Name</th>
                    {
                        getRounds(state.roundNr).map(
                            (i) => {
                                return <th className={getClasses('', i + 1, state)}>Game {i + 1}</th>
                            }
                        )
                    }
                </tr>
                {
                    Object.keys(state.leaderboard).map(
                        (el) => (<tr key={el}>
                            <td className={getClasses(el, 0, state)}>
                                <a onClick={(ev) => { ev.preventDefault(); goToBotDetail(el) }} href="">
                                    {el}
                                </a>
                            </td>
                            {
                                getRounds(state.roundNr).map((index) => {
                                    const score = state.leaderboard[el][index];
                                    return <td className={(score === 0 || score === null ? 'score-zero' : score === 1 ? 'score-one' : '') +
                                        ' ' + getClasses(el, index + 1, state)}>{score !== null ? score.toFixed(2) : '--'}</td>
                                })
                            }
                        </tr>)
                    )
                }
            </tbody>
        </table>
    </>
}