import { ActiveBot } from "./types"

export interface GameState {
    left: ActiveBot,
    right: ActiveBot
    leaderboard: {
        [key: string]: (number | null)[]
    },
    penalty: number,
    remaining: {
        total: number,
        remaining: number
    }
    direction: "in" | "accept" | "reject",
    roundNr: number
}

type GameStateMutation = {
    op: "game_set_current_pair",
    bots: {
        left: ActiveBot,
        right: ActiveBot
    }
} | {
    op: "game_update_scores",
    scores: {
        [key: string]: (number | null)[]
    },
    round_nr: number
} | {
    op: "game_accept"
} | {
    op: "game_reject"
} | {
    op: 'game_set_penalty',
    penalty: number
} | {
    op: 'game_set_remaining_bots',
    total: number,
    remaining: number
}

export function updateGameState(op: GameStateMutation, state: GameState | undefined): GameState {
    if (state === undefined) {
        console.warn("state was undefined");
        state = {
            left: {
                name: '',
                rank: 0,
                accepts: undefined,
                icon: '',
                top_dates: []
            },
            right: {
                name: '',
                rank: 0,
                accepts: undefined,
                icon: '',
                top_dates: []
            },
            leaderboard: {},
            remaining: {
                total: 0,
                remaining: 0
            },
            penalty: 1.0,
            direction: "in",
            roundNr: 0
        }
    }

    switch (op.op) {
        case 'game_set_current_pair':
            return {
                ...state,
                direction: 'in',
                left: op.bots.left,
                right: op.bots.right
            }
        case 'game_update_scores':
            return {
                ...state,
                leaderboard: op.scores,
                roundNr: op.round_nr
            }
        case 'game_set_penalty':
            return {
                ...state,
                penalty: op.penalty
            }
        case 'game_set_remaining_bots':
            return {
                ...state,
                remaining: {
                    total: op.total,
                    remaining: op.remaining
                }
            }
        case 'game_accept':
            return {
                ...state,
                direction: 'accept'
            }
        case 'game_reject':
            return {
                ...state,
                direction: 'reject'
            }
        default:
            return state
    }
}